import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import WithLayout from '../WithLayout';
import { t } from '../../domain/services/configService';
import { breakpointService } from '../../domain/services/breakpointService';
import { imageResizerService } from '../../domain/services/imageResizerService';
import { SubscribeModule } from './components/SubscribeModule';
import { SubscribeCard } from './components/SubscribeCard';
import { SmartLink } from '../../components/SmartLink';
import { grWrap } from '../../components/Styles';
import { theme } from '../../domain/theme';

const subsFooter = css`
  background-color: #000;
  min-height: 250px;
  text-transform: uppercase;
  width: 100%;

  ${theme.mq.tablet} {
    & {
      height: 320px;
      position: relative;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      height: 270px;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      height: 340px;
    }
  }
`;

const subsFooterImage = css`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  height: 180px;
  margin-bottom: 50px;
  width: 176px;

  ${theme.mq.tablet} {
    & {
      right: 80px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 248px;
      width: 168px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      height: 214px;
      width: 144px;
      right: 150px;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      width: 168px;
      height: 248px;
      right: 180px;
    }
  }
`;

const subsFooterText = css`
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 3px;
  line-height: 1.5;
  color: #fff;
  padding: 30px 0 20px;
  width: 265px;
  margin: 0 auto;

  ${theme.mq.tablet} {
    & {
      font-size: 1.5rem;
      line-height: 2rem;
      left: 80px;
      position: absolute;
      text-align: left;
      top: 50%;
      transform: translateY(-50%);
      width: 370px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      left: 120px;
      width: 500px;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      left: 180px;
    }
  }
`;

const subsHeader = css`
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: 2.5px;
  line-height: 1.5;
  color: #000;
  margin: 40px auto;
  text-transform: uppercase;
  width: 300px;

  ${theme.mq.tablet} {
    & {
      ${theme.font.family('regularWeb')};
      font-weight: 400;
      font-size: 2.25rem;
      letter-spacing: 2.5px;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 4px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      font-size: 2.5rem;
      line-height: 2.875rem;
      margin: 50px 0;
      width: 100%;
    }
  }
`;

const subsPrimary = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 1.5;
  font-style: normal;
  color: #fc0;
`;

const subs = css`
  background-color: #fff;
  position: relative;
  width: 100%;
  height: auto;
  overflow: visible;
  text-align: center;

  & .subs--hero + .subs--hero {
    border-top: none;
  }
`;

const subsMagazines = css`
  ${theme.mq.small_desktop} {
    & .subs__hero-cntr {
      border-top: none;
    }

    & .subs__hero-cntr:only-child {
      border-bottom: none;
    }

    & .subs__item-cntr:first-of-type {
      margin-right: 30px;
    }

    & .subs__item-cntr .subs__links ul li:not(:last-child) {
      margin-right: 25px;
    }
  }

  ${theme.mq.large_desktop} {
    & .subs__links ul li:not(:first-of-type) {
      margin-left: 35px;
    }

    & .subs__sale {
      margin-top: 32px;
      font-size: 0.8125rem;
    }
  }
`;

const SubscribePage = ({ content }) => {
  return (
    <section className="subs" css={subs}>
      <div className="gr-wrap" css={grWrap}>
        <div css={subsHeader}>{content.title}</div>
      </div>

      {content.mainContent.length === 4 && (
        <>
          <SubscribeModule issue={content.mainContent[0].entity} />
          <SubscribeModule issue={content.mainContent[1].entity} />
          <div css={subsMagazines} style={{ overflow: 'hidden' }}>
            <SubscribeCard issue={content.mainContent[2].entity} />
            <SubscribeCard issue={content.mainContent[3].entity} />
          </div>
        </>
      )}

      {content.mainContent.length === 2 && (
        <>
          <SubscribeModule issue={content.mainContent[0].entity} />
          <SubscribeModule issue={content.mainContent[1].entity} />
        </>
      )}

      {content.mainContent.length !== 4 && content.mainContent.length !== 2 && (
        <>
          <SubscribeModule issue={content.mainContent[0].entity} />
          <div css={subsMagazines} style={{ overflow: 'hidden' }}>
            {content.mainContent.map((mainContentItem, index) => {
              return index === 0 ? null : (
                <SubscribeModule
                  issue={mainContentItem.entity}
                  key={mainContentItem.entity.id}
                />
              );
            })}
          </div>
        </>
      )}

      {!content.hideEnglishLanguagePromo &&
        content.mainContent?.[0]?.entity?.issueLink?.uri && (
          <div css={subsFooter}>
            <SmartLink to={content.mainContent[0].entity.issueLink.uri}>
              <div css={subsFooterText}>
                <span css={subsPrimary}>{`${t('subscribe')} `}</span>
                {t('to national geographic english edition')}
              </div>
              {content.issueCoverEn?.entity?.mediaImage?.url && (
                <div
                  css={subsFooterImage}
                  style={{
                    backgroundImage: `url(${imageResizerService.getStyleUrl(
                      content.issueCoverEn.entity.mediaImage.url,
                      breakpointService.getTabletBreakpoint().width,
                      null,
                      { position: content.issueCoverEn.entity.focalPoint }
                    )})`
                  }}
                />
              )}
            </SmartLink>
          </div>
        )}
    </section>
  );
};

SubscribePage.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export default WithLayout(SubscribePage);
