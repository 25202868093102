import React from 'react';
import PropTypes from 'prop-types';
import { subsLinks } from './SubscribeStyles';

export const SubscribeLinks = ({ issue }) => (
  <div className="subs__links" css={subsLinks}>
    <ul>
      {issue.issueEversionItunes?.uri && (
        <li>
          <a href={issue.issueEversionItunes.uri}>iTunes</a>
        </li>
      )}
      {issue.issueEversionGooglePlay?.uri && (
        <li>
          <a href={issue.issueEversionGooglePlay.uri}>Google Play</a>
        </li>
      )}
      {issue.issueEversionZinio?.uri && (
        <li>
          <a href={issue.issueEversionZinio.uri}>Zinio</a>
        </li>
      )}
      {issue.issueEversionKiosko?.uri && (
        <li>
          <a href={issue.issueEversionKiosko.uri}>Kiosko</a>
        </li>
      )}
    </ul>
  </div>
);

SubscribeLinks.propTypes = {
  issue: PropTypes.objectOf(PropTypes.any).isRequired
};
