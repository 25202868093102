import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { t } from '../../../domain/services/configService';
import { SubscribeLinks } from './SubscribeLinks';
import { SmartLink } from '../../../components/SmartLink';
import { breakpointService } from '../../../domain/services/breakpointService';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { subsItem, subsImage, subsSale, subsHeroCntr } from './SubscribeStyles';
import { theme } from '../../../domain/theme';

const subsBodyCopyChapters = css`
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 2.5px;
  line-height: 1.5;
  color: #787878;
  display: none;
  text-transform: uppercase;

  ${theme.mq.small_desktop} {
    & {
      display: inline-block;
      font-size: 0.8125rem;
      line-height: 1.3125rem;
      text-align: center;
    }
  }
`;
const subsCountryOrders = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 2.5px;
  line-height: 1.5;
  margin: 30px 0 15px;
  text-transform: uppercase;

  & a {
    color: #000;
    position: relative;
  }

  & a:before {
    color: #fc0;
    position: absolute;
    right: -17px;
    font-size: 0.625rem;
    width: 10px;
    top: 45%;
    transform: translateY(-50%);
  }

  ${theme.mq.tablet} {
    & {
      margin-bottom: 40px;
    }
  }
`;
const subsEyebrows = css`
  ${theme.mq.small_desktop} {
    & {
      margin: 20px 0 30px;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      margin-bottom: 35px;
    }
  }
`;

const subsHeroImageDesktop = css`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: none;
  height: 500px;
  width: 100%;

  ${theme.mq.small_desktop} {
    & {
      display: inline-block;
      float: left;
      margin-top: 60px;
      min-height: 600px;
      width: 100%;
    }
  }
`;
const subsHeroImageCntr = css`
  ${theme.mq.small_desktop} {
    & {
      display: inline-block;
      float: left;
      width: 45%;
      height: 100%;
    }
  }
`;

const subsIssueMonth = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 2.5px;
  line-height: 1.5;
  color: #000;
  display: none;
  text-transform: uppercase;

  ${theme.mq.small_desktop} {
    & {
      display: block;
      text-align: center;
      margin-bottom: 5px;
    }
  }
`;
const subsMonths = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 2.5px;
  line-height: 1.5;
  font-style: normal;

  color: #000;
  display: none;
  text-transform: uppercase;

  ${theme.mq.small_desktop} {
    & {
      display: inline-block;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      padding: 0 40px;
    }
  }
`;

const subsYear = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 2.5px;
  line-height: 1.5;
  font-style: normal;

  color: #000;
  display: none;
  text-transform: uppercase;

  ${theme.mq.small_desktop} {
    & {
      border-left: 1px solid #000;
      display: inline-block;
      padding: 0 40px;
    }
  }
`;

const subsHero = css`
  & h6 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  & span p {
    font-size: 0.875rem;
  }

  & button {
    line-height: normal;
    min-height: 80px;
    padding-top: 5px;
  }

  ${theme.mq.tablet} {
    & .subs__links {
      display: block;
      float: none;
      margin: 0 0 60px;
    }

    & .subs__links ul li {
      margin: 0 17px;
    }

    & .subs__item h4 {
      margin-top: 25px;
    }

    & .subs__item h6 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }

    & .subs__item span p {
      line-height: 1.25rem;
    }

    & .subs__item button {
      width: 450px;
      height: 80px;
      line-height: 5rem;
      padding-top: 2px;
    }

    & .subs__image {
      margin: 30px auto;
    }

    & .subs__sale {
      margin-bottom: 30px;
    }

    & .${subsCountryOrders.name} {
      margin-bottom: 30px;
    }
  }

  ${theme.mq.small_desktop} {
    & .subs__item {
      border-bottom: none;
      float: right;
      width: 50%;
    }

    & .subs__item h6 {
      display: block;
      font-size: 1.875rem;
      line-height: 2.25rem;
    }

    & .subs__item span p {
      font-size: 0.8125rem;
      line-height: 1.25rem;
    }

    & .subs__item h4 {
      line-height: 2.25rem;
    }

    & .${subsBodyCopyChapters.name} {
      font-size: 0.8125rem;
      line-height: 1.3125rem;
    }

    & .subs__links {
      display: inline-block;
    }

    & .subs__links ul li {
      margin: 0;
    }

    & .subs__links ul li:not(:first-of-type) {
      margin-left: 43px;
    }

    & .subs__sale {
      margin: 23px 0 15px;
    }

    & .${subsMonths.name}, & .${subsYear.name} {
      height: 12px;
      line-height: 0.875rem;
    }
  }

  ${theme.mq.large_desktop} {
    & h4 {
      margin-top: 40px;
      line-height: 2.25rem;
    }

    & h6 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }

    & h7 {
      font-size: 0.8125rem;
    }

    & .subs__sale {
      margin: 30px 0 10px;
    }

    & .subs__sale p {
      font-size: 0.75rem;
    }

    & .${subsCountryOrders.name} {
      margin: 20px 0 35px;
    }

    & .${subsBodyCopyChapters.name} {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }

    & .subs__links {
      margin: 0;
    }

    & .subs__links ul li:not(:first-of-type) {
      margin-left: 35px;
    }

    & .subs__links ul li:not(:first-of-type) a {
      line-height: 1.1875rem;
    }

    & .subs__item button {
      width: 500px;
    }
  }
`;

export const SubscribeModule = ({ issue }) => (
  <div className="subs__hero-cntr subs--hero" css={[subsHeroCntr, subsHero]}>
    {issue.issueCover?.entity?.mediaImage?.url && (
      <div css={subsHeroImageCntr}>
        <div
          css={subsHeroImageDesktop}
          style={{
            backgroundImage: `url(${imageResizerService.getStyleUrl(
              issue.issueCover.entity.mediaImage.url,
              breakpointService.getTabletBreakpoint().width,
              null,
              { position: issue.issueCover.entity.focalPoint }
            )})`
          }}
        />
      </div>
    )}

    <div className="subs__item" css={subsItem}>
      <h6>{issue.issueHeader}</h6>
      {issue.issueSubheader?.value && (
        <span
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: issue.issueSubheader.value
          }}
        />
      )}

      <div css={subsEyebrows}>
        <div css={subsYear}>{issue.issueEyebrow}</div>
        <div css={subsMonths}>{issue.issueEyebrowIssues}</div>
      </div>

      {issue.issueDate?.value && (
        <div css={subsIssueMonth}>{issue.issueDate.value}</div>
      )}

      {issue.issueBody?.value && (
        <div
          css={subsBodyCopyChapters}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: issue.issueBody.value
          }}
        />
      )}

      <h4>{issue.issuePrice}</h4>
      <h6>{issue.issuePriceDescription}</h6>
      {issue.issueCover?.entity?.mediaImage?.url && (
        <div
          className="subs__image"
          css={subsImage}
          style={{
            backgroundImage: `url(${imageResizerService.getStyleUrl(
              issue.issueCover.entity.mediaImage.url,
              breakpointService.getTabletBreakpoint().width,
              null,
              { position: issue.issueCover.entity.focalPoint }
            )})`
          }}
        />
      )}

      <div
        className="subs__sale"
        css={subsSale}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: issue.issueInfoText.value
        }}
      />
      <a
        href={issue.issueSubscribeCta.uri}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button type="button">{t('Subscribe Today')}</button>
      </a>
      <div className="subs__country-orders" css={subsCountryOrders}>
        <SmartLink
          to={issue.issueLink.uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('Order the English edition')}
        </SmartLink>
      </div>

      <SubscribeLinks issue={issue} />
    </div>
  </div>
);

SubscribeModule.propTypes = {
  issue: PropTypes.objectOf(PropTypes.any).isRequired
};
