import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { t } from '../../../domain/services/configService';
import { SubscribeLinks } from './SubscribeLinks';
import { SmartLink } from '../../../components/SmartLink';
import { breakpointService } from '../../../domain/services/breakpointService';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { subsItem, subsImage, subsSale } from './SubscribeStyles';
import { theme } from '../../../domain/theme';

const subsImageDesktop = css`
  ${theme.mq.tablet} {
    & {
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      float: left;
      height: 400px;
      margin-top: 40px;
      width: 340px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      width: 100%;
      height: 300px;
      margin-top: 50px;
    }
  }
`;

const subsImageCntr = css`
  ${theme.mq.small_desktop} {
    & {
      width: 45%;
      display: inline-block;
      height: 100%;
      margin-right: 30px;
    }
  }
`;

const subsItemCntr = css`
  ${theme.mq.tablet} {
    & {
      border-top: 1px solid #000;
      display: inline-block;
      height: auto;
      width: 100%;
    }

    & .subs__links {
      clear: both;
      display: inline-block;
      float: left;
      margin-left: 45px;
    }

    & .subs__links ul li {
      margin: 0;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      border: none;
      float: left;
      height: auto;
      width: 48%;
    }
  }
`;

const subsColumn = css`
  border-top: 1px solid #000 !important;

  & span p {
    font-size: 0.875rem;
  }

  ${theme.mq.tablet} {
    & {
      border-top: none !important;
      float: right;
      text-align: initial;
      width: 48%;
    }

    & h6 {
      line-height: 2rem;
      margin-bottom: 30px;
    }

    & h4 {
      font-size: 1.625rem;
      line-height: 2rem;
      margin-top: 42px;
    }

    & h7 {
      font-size: 0.9375rem;
    }

    & button {
      font-size: 0.8125rem;
    }

    & .subs__image {
      display: none;
    }

    & .subs__item h4 {
      margin: 40px 0 10px;
    }

    & .subs__sale {
      font-size: 0.9375rem;
      margin: 30px 0 15px;
    }

    & .subs__country-orders {
      display: none;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      border: none;
      margin-top: 50px;
      text-align: left;
      width: 47%;
    }

    & h6 {
      line-height: 2rem;
    }

    & h4 {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    & span p {
      font-size: 0.8125rem;
      line-height: 1.25rem;
      height: 87px;
      overflow: hidden;
    }

    & h7 {
      font-size: 0.8125rem;
    }

    & .subs__country-orders {
      display: none;
    }

    & .subs__links {
      margin-bottom: 70px;
    }

    & .subs__sale {
      font-size: 0.8125rem;
      margin: 22px 0 5px;
    }

    & button {
      font-size: 0.9375rem;
    }
  }

  ${theme.mq.large_desktop} {
    & button {
      font-size: 0.9375rem;
    }

    & {
      margin-top: 45px;
    }

    & h6 {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    & h4 {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    & h7 {
      font-size: 0.8125rem;
    }

    & button {
      width: 240px;
    }
  }
`;

export const SubscribeCard = ({ issue }) => (
  <div className="subs__item-cntr" css={subsItemCntr}>
    {issue.issueCover?.entity?.mediaImage?.url && (
      <div className="subs__image-cntr" css={subsImageCntr}>
        <div
          css={subsImageDesktop}
          style={{
            backgroundImage: `url(${imageResizerService.getStyleUrl(
              issue.issueCover.entity.mediaImage.url,
              breakpointService.getTabletBreakpoint().width,
              null,
              { position: issue.issueCover.entity.focalPoint }
            )})`
          }}
        />
      </div>
    )}

    <div className="subs__item" css={[subsItem, subsColumn]}>
      <h6>{issue.issueHeader}</h6>
      {issue.issueSubheader?.value && (
        <span
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: issue.issueSubheader.value
          }}
        />
      )}

      <h4>{issue.issuePrice}</h4>
      <h6>{issue.issuePriceDescription}</h6>

      {issue.issueCover?.entity?.mediaImage?.url && (
        <div
          className="subs__image"
          css={subsImage}
          style={{
            backgroundImage: `url(${imageResizerService.getStyleUrl(
              issue.issueCover.entity.mediaImage.url,
              breakpointService.getTabletBreakpoint().width,
              null,
              { position: issue.issueCover.entity.focalPoint }
            )})`
          }}
        />
      )}
      <div
        className="subs__sale"
        css={subsSale}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: issue.issueInfoText.value
        }}
      />

      {issue.issueSubscribeCta.uri && (
        <SmartLink
          to={issue.issueSubscribeCta.uri}
          target="_blank"
          rel="noopener noreferrer"
          css={css`
            display: 'block';
          `}
        >
          <button type="button">{t('Subscribe')}</button>
        </SmartLink>
      )}

      <SubscribeLinks issue={issue} />
    </div>
  </div>
);

SubscribeCard.propTypes = {
  issue: PropTypes.objectOf(PropTypes.any).isRequired
};
