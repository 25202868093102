import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';

export const subsLinks = css`
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: 3px;
  line-height: 1.5;
  color: #000;
  margin-bottom: 30px;
  text-transform: uppercase;

  & ul li {
    margin-bottom: 10px;
  }

  & ul li a {
    color: #000;
    font-size: 0.8125rem;
  }

  font-size: 1.5rem;
  line-height: 1.75rem;

  ${theme.mq.tablet} {
    & {
      display: inline-block;
      float: left;
      margin: 30px 0 60px;
    }

    & ul li {
      display: inline;
      margin: 0 40px;
    }

    & ul li a {
      letter-spacing: 0.26em;
    }
  }

  ${theme.mq.small_desktop} {
    & ul li {
      float: left;
      line-height: 1.1875rem;
      margin: 0;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      float: left;
    }
  }
`;

export const subsImage = css`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 500px;
  width: 100%;
  margin: 25px 0;

  ${theme.mq.tablet} {
    & {
      width: 450px;
      margin: 40px auto;
      height: 650px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      display: none;
    }
  }
`;

export const subsItem = css`
  & h4,
  & h6 {
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    letter-spacing: 0.3px;
  }

  & h4 {
    ${theme.font.family('boldWeb')};
    font-weight: 400;
    font-size: 1.875rem;
    letter-spacing: 0.3px;
    line-height: 1.5;
  }

  & h6 {
    ${theme.font.family('boldWeb')};
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.3px;
    line-height: 1.5;
  }

  border-top: 1px solid #555;

  & h6 {
    margin: 40px 0 0;
    text-transform: capitalize;
  }

  & span {
    color: #555;
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    letter-spacing: 0.14em;
    font-size: 0.8125rem;
    line-height: 1.375rem;
  }

  & h4 {
    color: #000;
    margin: 20px 0 0;
  }

  & h7 {
    color: #555;
    ${theme.font.family('boldWeb')};
    font-weight: 400;
    font-size: 0.8125rem;
    letter-spacing: 0.3px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  & button {
    ${theme.font.family('boldWeb')};
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 1.5;
    font-style: normal;
    font-size: 1.0625rem;
    background-color: #000;
    border: none;
    color: #fc0;
    line-height: 5rem;
    height: 80px;
    text-transform: uppercase;
    text-align: center;
    width: 300px;
  }

  & button a {
    color: inherit;
  }

  ${theme.mq.tablet} {
    & button {
      width: 240px;
      height: 50px;
      line-height: 3.125rem;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      border-top: none;
      border-bottom: 1px solid #000;
      margin-top: 60px;
    }

    & h6 {
      margin-top: 0;
    }

    & h4 {
      line-height: 1.875rem;
      margin: 10px 0 0;
    }

    & button {
      height: 50px;
      width: 202px;
    }

    & button:hover {
      color: #fff;
    }
  }

  ${theme.mq.large_desktop} {
    & span p {
      line-height: 1.25rem;
    }
  }
`;

export const subsSale = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 2.5px;
  line-height: 1.5;
  color: #000;
  margin-bottom: 10px;
  text-transform: uppercase;

  font-size: 0.75rem;

  ${theme.mq.tablet} {
    & {
      margin-bottom: 30px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      margin: 10px 0 5px;
    }
  }

  ${theme.mq.large_desktop} {
    & p {
      font-size: 0.8125rem;
      line-height: 1.1875rem;
    }
  }
`;

export const subsHeroCntr = css`
  ${theme.mq.tablet} {
    & {
      display: inline-block;
      width: 100%;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      padding-bottom: 50px;
      width: 100%;
    }

    & button {
      width: 450px;
      height: 80px;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      padding-bottom: 90px;
    }
  }
`;
