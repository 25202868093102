import { css } from '@emotion/react';
import { theme } from '../../domain/theme';

export const grWrap = css`
  max-width: 68em;
  margin-left: auto;
  margin-right: auto;
  margin: 0 30px;

  &::after {
    clear: both;
    content: '';
    display: table;
  }

  & .grid-full {
    margin-left: -30px;
    margin-right: -30px;
  }

  ${theme.mq.tablet} {
    & {
      margin: 0 40px;
    }

    & .grid-full {
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      max-width: none;
      margin: 0 60px;
    }

    & .grid-full {
      margin-left: -60px;
      margin-right: -60px;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      max-width: 1600px;
      padding: 0 60px;
      margin: 0 auto;
    }
  }
`;

export const vidLanding = css`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
  padding: 0;

  & .fhero-pack .title {
    border-left: none;
  }

  & .fhero-pack .title:before {
    content: '';
    width: 1px;
    display: block;
    position: absolute;
    background: #fc0;
    width: 3px;
    height: 27px;
    left: 0;
    top: 3px;
  }

  & .top-ad {
    display: inline-block;
    margin: 45px auto 10px;
  }

  ${theme.mq.tablet} {
    & .top-ad {
      margin: 60px auto;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      padding: 0;
    }

    & .top-ad {
      margin: 45px auto;
    }

    & .fhero-pack .title:before {
      height: 37px;
    }

    & .newsletter__info {
      margin-top: 20px;
    }
  }
`;
